<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <h1>{{ this.pageTitle }}</h1>
    <h6>
      {{ `${$t("column.questionCount")} ${practice.exam.questions.length}` }}
    </h6>
    <h6>
      {{
        `${$t("practice.form.needToTimer")} ${
          practice.has_timing
            ? `${practice.timing_minutes}:${practice.timing_seconds}`
            : "--"
        }`
      }}
    </h6>
    <h6>
      {{
        `${$t("practice.form.limit")} ${
          practice.has_expire ? `${practice.expire_end}` : "--"
        }`
      }}
    </h6>
    <el-alert type="success" :closable="false">
      <div style="font-size: 16px">
        {{ $t("practice.pleasePressStartPractice") }}
      </div>
    </el-alert>
    <el-button
      type="primary"
      style="width: 400px; margin-top: 20px"
      @click="() => startPractice($route.params.practiceId)"
    >
      {{ $t("practice.start") }}
    </el-button>
  </div>
</template>

<script>
import practiceApi from "@/apis/practices";
import Breadcrumb from "@/components/Breadcrumb";
import Practice from "@/views/practices/Practice";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: { Breadcrumb },
  data() {
    return {
      practice: null
    };
  },
  computed: {
    pageTitle() {
      return this.practice ? this.practice.exam.title : "";
    }
  },
  async created() {
    await this.fetchPractice();
  },
  methods: {
    async fetchPractice() {
      const { practice } = await practiceApi.fetchPractice(
        this.$route.params.practiceId
      );
      this.practice = new Practice(practice);
    },
    async startPractice(pracitceId) {
      const exam = await practiceApi.checkAlreadyTakenExam(pracitceId);
      let userExamId = exam.user_exam_id;

      if (userExamId) {
        try {
          await this.$confirm(
            this.$t("practice.alreadyHasPractice"),
            this.$t("message.notice"),
            {
              confirmButtonText: this.$t("message.continue"),
              cancelButtonText: this.$t("practice.startNewPractice"),
              type: "warning"
            }
          );
        } catch (e) {
          await practiceApi.deletePracticeUserExam(userExamId);
          userExamId = null;
        }
      }

      if (!userExamId) {
        const { user_exam } = await practiceApi.takePracticeExam(pracitceId);
        userExamId = user_exam.id;
      }

      this.$router.push({
        name: "PracticeQuestion",
        params: {
          practiceId: this.$route.params.practiceId,
          userExamId: userExamId,
          questionOrder: 1
        }
      });
    }
  }
};
</script>

<style>
.questionOrderLink {
  cursor: pointer;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: #fff;
  text-align: center;
  line-height: 25px;
  border: #ddd 4px solid;
  margin: 0px 8px 8px 0px;
}

.activeQuestion {
  border: #42a16a 4px solid;
}

.questionOrderLink:hover {
  border: #42a16a 4px solid;
}

.border {
  border: 1px solid #ccc;
  padding: 2%;
  overflow: auto;
  margin: 0 15px;
  max-height: 600px;
}
</style>
